import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import style from './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(

  <App/>

);
