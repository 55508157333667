import { Col, Container, ModalTitle, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

function BasicExample() {
  return (
    <div
      style={{ paddingTop: "4rem", paddingBottom: "3rem" }}
      className=" ourbg  d-flex align-items-center justify-content-center flex-column gradient"
    >
      <h1>
        <strong>Frequently Asked Questions</strong>{" "}
      </h1>
      <Container className="mt-2 p-5">
        <Row className="mt-4">
          <Col>
            {" "}
            <Accordion defaultActiveKey="0" style={{ color: "green" }}>
              <Accordion.Item
                eventKey="0"
                className="mb-3 p-3  rounded-4 shadow faq"
              >
                <Accordion.Header>
                  {" "}
                  <b>PhysiTask kaise kaam karta hai?</b>{" "}
                </Accordion.Header>
                <Accordion.Body>
                  PhysiTask 10km ke range ke andar diffrent prakar ke kaamon ke
                  liye achhe karmchariyon ko Kamm-dene wale logon se jodta hai.
                  Bas ek Task post karein, available worker ko dekhein, aur apne
                  project/Task ke liye sabse Badhiya vyakti ko Kaam par
                  lagayein.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="1"
                className="mb-3 p-3  rounded-4 shadow faq"
              >
                <Accordion.Header>
                  {" "}
                  <b>
                    PhysiTask par main kaun-kaun se kaamon ko dhoondh sakta
                    hoon?
                  </b>{" "}
                </Accordion.Header>
                <Accordion.Body>
                  PhysiTask ghar ki marammat, babysitter, pet sitter,
                  elctrician, plumber, safai sevayein, bagwani, gardening,
                  writing, aur vyaktigat sahayata jaise kai saare kaamon ko
                  present karta hai. PhysiTask platform par aap anek prakar ke
                  kaamon ko dhund sakte ya karwa sakte hain .
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="2"
                className="mb-3 p-3  rounded-4 shadow faq"
              >
                <Accordion.Header>
                  {" "}
                  <b>Kya PhysiTask istemal karne mein surakshit hai?</b>{" "}
                </Accordion.Header>
                <Accordion.Body>
                Haan, PhysiTask dono pakshon ke liye suraksha aur safety ko
            preference deta hai. Hum apne upyogkartaon ki adhikatam safety
            provide karne ke liye sakht background verification process
            apnate hain.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col>
            {" "}
            <Accordion defaultActiveKey="0" style={{ color: "green" }}>
              <Accordion.Item
                eventKey="0"
                className="mb-3 p-3  rounded-4 shadow faq"
              >
                <Accordion.Header>
                  {" "}
                  <b>PhysiTask kaise kaam karta hai?</b>{" "}
                </Accordion.Header>
                <Accordion.Body>
                  PhysiTask 10km ke range ke andar diffrent prakar ke kaamon ke
                  liye achhe karmchariyon ko Kamm-dene wale logon se jodta hai.
                  Bas ek Task post karein, available worker ko dekhein, aur apne
                  project/Task ke liye sabse Badhiya vyakti ko Kaam par
                  lagayein.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="1"
                className="mb-3 p-3  rounded-4 shadow faq"
              >
                <Accordion.Header>
                  {" "}
                  <b>
                    PhysiTask par main kaun-kaun se kaamon ko dhoondh sakta
                    hoon?
                  </b>{" "}
                </Accordion.Header>
                <Accordion.Body>
                  PhysiTask ghar ki marammat, babysitter, pet sitter,
                  elctrician, plumber, safai sevayein, bagwani, gardening,
                  writing, aur vyaktigat sahayata jaise kai saare kaamon ko
                  present karta hai. PhysiTask platform par aap anek prakar ke
                  kaamon ko dhund sakte ya karwa sakte hain .
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="2"
                className="mb-3 p-3  rounded-4 shadow faq"
              >
                <Accordion.Header>
                  {" "}
                  <b>Kya PhysiTask istemal karne mein surakshit hai?</b>{" "}
                </Accordion.Header>
                <Accordion.Body>
                Haan, PhysiTask dono pakshon ke liye suraksha aur safety ko
            preference deta hai. Hum apne upyogkartaon ki adhikatam safety
            provide karne ke liye sakht background verification process
            apnate hain.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
         
        </Row>
      </Container>
    </div>
  );
}

export default BasicExample;
