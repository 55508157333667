import React from "react";
import "./HowItWorks.css";
import { Card } from "react-bootstrap";

function HowItWorks() {
  return (
    <div className="how-it-works gradient p-5 d-flex align-items-center flex-column">
      <h3 className="text-light shadow-lg w-50 rounded-pill p-1"><b>Yeh Kaise Kaam Karta Hai?</b></h3>

      <div className="steps-container">
        <Card className="step shadow-lg">
          {/* <div className="step-icon">*</div> */}
          <h3 className="fw-bold">Task Dhundho</h3>
          <p>
            Apne area mein aise kaam dhoondiye jo aapke skills aur pasand ke
            anusaar ho.
          </p>
        </Card>
        <Card className="step shadow-lg">
          {/* <div className="step-icon">*</div> */}
          <h4 className="fw-bold">Freelancer/Worker Hire Karo</h4>
          <p>
            Yogya freelancer/worker ki list se chunav kijiye aur kaam ke liye sabse
            accha vyakti hire kijiye.
          </p>
        </Card>
        <Card className="step shadow-lg">
          {/* <div className="step-icon">*</div> */}
          <h4 className="fw-bold">Kaam Pura Karo</h4>

          <p>
            Platform ke through surakshit tarike se task ko poora karne aur
            Payment karne ke liye miljul kar kaam kijiye.
          </p>
        </Card>
      </div>
    </div>
  );
}

export default HowItWorks;
